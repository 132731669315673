import { createContext, useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import ToDoListTable from './TodoListTable';
import AddFormModal from './AddFormModal';
import OpenFormModal from './OpenFormModal';
import formsService from "../services/FormsService";
import { FormDataContextProvider } from '../contexts/FormDataContext';
import { UserContext } from '../contexts/UserContext';
import { Model } from 'survey-core';
import { useSnackbar } from 'notistack';
import { cardSX, inceptiaGreenAlphaColors } from '../utilities/CSS';
import { iTodo } from '../utilities/APIInterfaces';
import { Status } from '../utilities/FormEnums';
import ContributorDialog from './ContributorDialog';

export type FormContextType = {
  addFormIsOpen: boolean;
  openFormIsOpen: boolean;
  openForm: iTodo | null;
  openContributorDialog: boolean;
  setAddFormIsOpen: (addFormIsOpen: boolean) => void;
  setOpenFormIsOpen: (openFormIsOpen: boolean) => void;
  setOpenForm: (openForm: iTodo | null) => void;
  setOpenContributorDialog: (openContributorDialog: boolean) => void;
}
export const CurrentFormContext = createContext<FormContextType>({
  addFormIsOpen: false,
  openFormIsOpen: false,
  openForm: null,
  openContributorDialog: false,
  setAddFormIsOpen: () => {},
  setOpenFormIsOpen: () => {},
  setOpenForm: () => {},
  setOpenContributorDialog: () => {}
});
export interface ToDoListProps {
  isToDoListDataLoaded: boolean;
  setIsToDoListDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ToDoList(props : ToDoListProps) {
  type ToDoListViewType = 'active' | 'completed';

  const [todoListCount, setTodoListCount] = useState(0);
  const [todoListView, setTodoListView] = useState<ToDoListViewType>('active');

  const [addFormIsOpen, setAddFormIsOpen] = useState(false);
  const [openFormIsOpen, setOpenFormIsOpen] = useState(false);
  const [openContributorDialog, setOpenContributorDialog] = useState(false);
  const [openForm, setOpenForm] = useState<iTodo | null>(null);
  
  const [ todoListData, setTodoListData ] = useState<iTodo[]>([]);
  const userContext = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();
  
  const currentUserAuthId = userContext.user?.authId?.toString() || '';

  const toDoListTitleText = () => {
    if ( ! props.isToDoListDataLoaded) return 'To-Do List (Loading...)';
  
    if (todoListCount > 0) return `To-Do List (${todoListCount})`;

    if (todoListView === 'active') return 'To-Do List (Active)';

    return 'To-Do List (Completed)';
  };

  const handleViewChange = () => {
    todoListView === 'active' ? setTodoListView(prev => prev = 'completed') : setTodoListView(prev => prev = 'active');
  };

  const handleOpenAddFormModalClick = () => {
    setAddFormIsOpen(true);
    setOpenForm(null);
  };

  async function GetToDoListDataAsync(authId: string | undefined) {
    if (authId) {
      formsService.GetToDoListData(authId, (v) => v)
        .then((result) => {
          if (result) {
            const todoListData = result as iTodo[];
            setTodoListData(todoListData);
          } else {
            // User may have de-selected all the forms in the Add Form modal...
            setTodoListData([]);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.toString());
          console.log("GetToDoListDataAsync 1", error);
        }).finally(() => {
          props.setIsToDoListDataLoaded(true);
        });
    }
  }
  useEffect(() => {
    GetToDoListDataAsync(currentUserAuthId);    
  }, [props.isToDoListDataLoaded, currentUserAuthId, enqueueSnackbar]);

  useEffect(() => {
    if (!openFormIsOpen && !addFormIsOpen && !openContributorDialog) props.setIsToDoListDataLoaded(false);
  }, [openFormIsOpen, addFormIsOpen, openContributorDialog]);

  return (
    <FormDataContextProvider>
      <CurrentFormContext.Provider value={{ 
        addFormIsOpen,  setAddFormIsOpen, 
        openFormIsOpen, setOpenFormIsOpen,
        openForm, setOpenForm,
        openContributorDialog, setOpenContributorDialog
      }}>
        <Container sx={cardSX.container}>
          <Typography variant='h2' sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 500,
            ...(! props.isToDoListDataLoaded || todoListData.length > 0 ? { mb: 4.5 } : { mb: 0 /* Empty state */ })
          }}>
            {toDoListTitleText()}
            {props.isToDoListDataLoaded && <Link
              component='button'
              underline='hover'
              variant='body1'
              onClick={handleViewChange}
              sx={{
                fontWeight: 500,
                ml: 2
              }}
            >{todoListView === 'active' ? 'View Completed' : 'View Active'}</Link>}

            {/* Want the button to "inherit" the InceptiaGreen color from the Box */}
            <Box color='InceptiaGreen.main' component='span' sx={{ ml: 'auto' }}><Button
              variant='outlined'
              color='inherit' // Key to less sx rules
              size="small"
              onClick={handleOpenAddFormModalClick}
              sx={{
                ...(addFormIsOpen && {cursor: 'wait'}),
                textTransform: 'none',
                '&:hover, &:focus-visible': {
                  backgroundColor: inceptiaGreenAlphaColors.hover
                }
              }}
            >
              Add Form
            </Button></Box>
          </Typography>

          <ToDoListTable
            todoListView={todoListView}
            isToDoListDataLoaded={props.isToDoListDataLoaded}
            onTodoListCountChange={setTodoListCount}
            setIsToDoListDataLoaded={props.setIsToDoListDataLoaded}

            // FEBS-583 - Which statuses on which "tabs" (active/completed):
            // Active: Not Started (Status is NULL), Incomplete (1), Submitted (2), In-Review (5), Action Needed (6)
            // Completed: Declined (3), Approved (4), Removed (7), In-Eligible (8), Suspended (9)
            rowData={todoListView === 'active' ?
              todoListData?.filter(form => ! [Status.DECLINED, Status.APPROVED, Status.REMOVED, Status.INELIGIBLE, Status.SUSPENDED].includes(form.statusId || 0)) :
              todoListData?.filter(form => [Status.DECLINED, Status.APPROVED, Status.REMOVED, Status.INELIGIBLE, Status.SUSPENDED].includes(form.statusId || 0))}
          />

          {addFormIsOpen && <AddFormModal
            setIsToDoListDataLoaded={props.setIsToDoListDataLoaded}
          />}
          {openFormIsOpen && <OpenFormModal 
            openForm={openForm} 
            survey={new Model(JSON.parse(openForm?.formJson || ""))} 
            setTodoListView={setTodoListView}
          />}
          {}

          {openContributorDialog && <ContributorDialog todo={openForm || undefined} uuid={currentUserAuthId} onClose={() => { setOpenContributorDialog(false);}}/>}
        </Container>
      </CurrentFormContext.Provider>
    </FormDataContextProvider>
  );
}
