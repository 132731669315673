import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import Container from '@mui/material/Container/Container';
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import Table from "@mui/material/Table/Table";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody/TableBody";
import { NavLink } from "react-router-dom";
import ContributorDialog from "./ContributorDialog";
import { iUserLink } from "../utilities/APIInterfaces";
import userService from "../services/UserService";

export default function ProfileFormConnections() {
    const userContext = useContext(UserContext);
    const [contributorDialogIsOpen, setContributorDialogIsOpen] = useState(false);
    const [contributingToLinks, setContributingToLinks] = useState([] as iUserLink[]);
    const [contributorLinks, setContributorLinks] = useState([] as iUserLink[]);
    // const [userLinks, setUserLinks] = useState([] as iUserLink[]);

  
  useEffect(() => { 
    let contributingToLinks: iUserLink[] = [];
    let contributorLinks: iUserLink[] = [];
    if (userContext.user?.authId) {
      userService.GetUserLinks(userContext.user?.authId).then((data: iUserLink[]) => {
        data.forEach((link) => {
            if(link.status !== 'Request'){
              if(link.userIsContributor){
                contributingToLinks.push(link);
              }else{
                contributorLinks.push(link);
              }
            }
        });
        setContributingToLinks(contributingToLinks);
        setContributorLinks(contributorLinks);
      });
    }
}, [userContext.user?.authId]);
  
    return (
      <Container sx={{py:3}}>
        <Button
          onClick={()=>{setContributorDialogIsOpen(true)}}
          variant='contained'
          size='large'
          type='submit'
          sx={{
            float: 'right',
            fontSize: '1rem',
            px: 5,
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark'
            }
          }}
        >
          Invite Parent
        </Button>
        {contributorLinks.length > 0 &&
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Typography variant='h5' sx={{fontWeight: 500, mb: 4}}>
            Linked Parents Accounts
          </Typography>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Status</TableCell>
                  {/* <TableCell align="center">&nbsp;</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {contributorLinks.map((row) => (
                  <TableRow
                    key={row.Id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center" sx={{height:'75px', }}>
                      {row.contributorName}
                    </TableCell>
                    <TableCell align="center">{row.contributorEmail}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    {/* <TableCell align="center">
                      <NavLink to="/dashboard/connections" style={{ textDecoration: 'none', color: "var(--link-color)", fontWeight: "500" }}>
                        <Typography variant='body2'>
                            Edit
                          </Typography>
                        </NavLink>
                      </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
    }
        
        {contributingToLinks.length > 0 &&
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Typography variant='h5' sx={{fontWeight: 500, mb: 4}}>
            Linked Student Accounts
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Status</TableCell>
                  {/* <TableCell align="center">&nbsp;</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {contributingToLinks.map((row) => (
                  <TableRow
                    key={row.Id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center" sx={{height:'75px', }}>
                      {row.userName}
                    </TableCell>
                    <TableCell align="center">{row.userEmail}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    {/* <TableCell align="center">
                      <NavLink to="/dashboard/connections" style={{ textDecoration: 'none', color: "var(--link-color)", fontWeight: "500" }}>
                        <Typography variant='body2'>
                            Edit
                          </Typography>
                        </NavLink>
                      </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        }
        {contributorDialogIsOpen && <ContributorDialog uuid={userContext.user?.authId ?? ''} onClose={()=>{setContributorDialogIsOpen(false)}} />}
      </Container>
    );
  }