/* tslint:disable */
/* eslint-disable */
/**
 * notification-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationTemplateStatusEnum {
    PendingApproval = 'Pending Approval',
    Approved = 'Approved',
    ChangesRequired = 'Changes Required',
    Rejected = 'Rejected'
}

