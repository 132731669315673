import { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemText, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import { AdminFormSubmissionsDataContext, AdminFormSubmissionsDataOptionsType, iAdminFormSubmissionsData } from '../contexts/AdminFormSubmissionsDataContext';
import { iUserDTO, iFormTag } from '../utilities/APIInterfaces';
import { inceptiaGreenAlphaColors } from '../utilities/CSS';
import { Status, StatusLabel } from '../utilities/FormEnums';
import { createCsvContent, downloadCsv, escapeRegex, formatDate, formatPhoneNumber, formatSSN } from '../utilities/HelperFunctions';
import TableRowsLoader, { type TableLoaderProps } from './TableRowsLoader';
import ConfirmationDialog from './ConfirmationDialog';
import adminFormsService from '../services/AdminFormsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateRange } from './DateRange';
import tagService from '../services/TagService';
import NotificationForm from './notifications/NotificationForm';
import { OrganizationContext } from '../contexts/OrganizationContext';

type Order = 'asc' | 'desc';
type AutoCompleteOptionsType = { label: string; value: string };

type MoreFiltersOptions = {
  moreFiltersIsOpen: boolean;
  isMoreFiltering: boolean;
  assignedToData: AutoCompleteOptionsType[];
  setAssignedToData: (value: AutoCompleteOptionsType[]) => void;
  selectedAssignedTo: AutoCompleteOptionsType[];
  tags: AutoCompleteOptionsType[];
  setTags: (value: AutoCompleteOptionsType[]) => void;
  selectedTags: AutoCompleteOptionsType[];
  selectedDateRange: string[];
  origSelectedAssignedTo: AutoCompleteOptionsType[];
  origSelectedTags: AutoCompleteOptionsType[];
  origSelectedDateRange: string[];
  setMoreFiltersIsOpen: (value: boolean) => void;
  setIsMoreFiltering: (value: boolean) => void;
  setSelectedAssignedTo: (value: AutoCompleteOptionsType[]) => void;
  setSelectedTags: (value: AutoCompleteOptionsType[]) => void;
  setSelectedDateRange: (value: string[]) => void;
  setOrigSelectedAssignedTo: (value: AutoCompleteOptionsType[]) => void;
  setOrigSelectedTags: (value: AutoCompleteOptionsType[]) => void;
  setOrigSelectedDateRange: (value: string[]) => void;
};

type ActionsButtonProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  isActionsMenuOpen: boolean;
  handleActionsMenuItemClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  disableChangeOptions: boolean;
};

const csvFileName = "FormSummary.csv";

const ActionsButton = ({ anchorEl, setAnchorEl, isActionsMenuOpen, handleActionsMenuItemClick, disableChangeOptions }: ActionsButtonProps) => {
  return (
    <>
      <Button
        id='student-actions-button'
        aria-controls={isActionsMenuOpen ? 'student-actions-menu' : undefined}
        aria-expanded={isActionsMenuOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Student actions'
        color='inherit'
        variant='outlined'
        onClick={(e) => setAnchorEl(e.currentTarget)}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          // Keyboard accessibility - open the menu when pressing enter or space
          if (e.key === 'Enter' || e.key === ' ') setAnchorEl(e.currentTarget);
        }}
        sx={{
          fontSize: '1rem',
          pr: 0.5,
          textTransform: 'none'
        }}
      >Actions<MoreVertIcon /></Button>
      <Menu
        id='student-actions-menu'
        anchorEl={anchorEl}
        open={isActionsMenuOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'student-actions-button',
          dense: true,
          sx: {
            minWidth: '180px',
            textAlign: 'right'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          data-menu-action='export'
          onClick={handleActionsMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Export Submissions</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableChangeOptions}
          data-menu-action='status'
          onClick={handleActionsMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Change Status</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableChangeOptions}
          data-menu-action='assignee'
          onClick={handleActionsMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Change Assignee</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableChangeOptions}
          data-menu-action='tags'
          onClick={handleActionsMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Change Tags</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disableChangeOptions}
          data-menu-action='notifications'
          onClick={handleActionsMenuItemClick}
        >
          <ListItemText primaryTypographyProps={{ fontWeight: '500' }}>Send Notifications</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const HighlightedText = ({ text = '', highlight = '', ignoreChars = '' }) => {

  if ( ! highlight.trim()) return <span>{text}</span>;

  // Lot simpler if we don't have to deal with ignoreChars (formatted phone numbers, etc.)
  if ( ! ignoreChars.trim()) {
    const highlightRegex = new RegExp(`(${escapeRegex(highlight)})`, 'gi');
    const textParts = text.split(highlightRegex);
  
    return (
      <span>
        {textParts
          // Remove any empty strings from the array as a result of the split
          .filter(part => part)
          .map((part, index) => (
            highlightRegex.test(part) ? (
              <mark key={index}>{part}</mark>
            ) : (
              <span key={index}>{part}</span>
            )
          ))}
      </span>
    );
  }

  // Getting more complicated...
  const ignoreSpaces = ignoreChars.includes(' ') ? true : false;
  const ignoreCharsRegex = new RegExp(`[${escapeRegex(ignoreChars)}]`, 'g');

  // Remove the characters we want to ignore from both highlight and text variables
  const cleanHighlight = highlight.replace(ignoreCharsRegex, '');
  const cleanText = text.replace(ignoreCharsRegex, '');

  const regex = new RegExp(`(${escapeRegex(cleanHighlight)})`, 'gi');
  const match = regex.exec(cleanText);

  // If there is no match, just return the original text
  if ( ! match) return <span>{text}</span>;

  let startIndex = match.index;

  // Move the startIndex forward by one for each character/space we want to ignore
  for (let i = 0; i < text.length; i++) {
    if ((ignoreCharsRegex.test(text[i]) || (ignoreSpaces && text[i] === ' ')) && i <= startIndex) startIndex++;
  }

  // Set the endIndex to the startIndex plus the length of the matched text (startIndex may have moved)
  let endIndex = startIndex + match[0].length;

  // Move the endIndex forward by one for each character/space we want to ignore
  for (let i = startIndex; i < text.length; i++) {
    if ((ignoreCharsRegex.test(text[i]) || (ignoreSpaces && text[i] === ' ')) && i < endIndex) endIndex++;
  }

  return (
    <span>
      {text.substring(0, startIndex)}
      <mark>{text.substring(startIndex, endIndex)}</mark>
      {text.substring(endIndex)}
    </span>
  );
};

type MoreFiltersAutocompleteProps = {
  ariaSuffix: string;
  autoFocus?: boolean;
  label: string;
  limitTags?: number;
  options: AutoCompleteOptionsType[];
  noOptionsText?: string;
  sx?: object;
  handleAutoCompleteChange: (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteOptionsType[]) => void
  value?: AutoCompleteOptionsType[]
}
const MoreFiltersAutocomplete = (props: MoreFiltersAutocompleteProps) => {
  const { ariaSuffix, autoFocus = false, label, handleAutoCompleteChange, ...rest } = props;
  return (
    <Autocomplete
      {...rest}
      id={`more-filters-${ariaSuffix}`}
      data-ac-type={`${ariaSuffix}`}
      clearOnEscape
      disableCloseOnSelect
      fullWidth
      multiple
      size='small'
      onChange={handleAutoCompleteChange}
      renderInput={(params) =>
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={label}
        />
      }
      // Style the selected items in the dropdown list
      slotProps={{
        popper: {
          sx: {
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            },
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: inceptiaGreenAlphaColors.selected
            },
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
              backgroundColor: inceptiaGreenAlphaColors.selectedHover
            }
          }
        }
      }}
    />
  )
};

type MoreFiltersDialogProps = {
  mfo: MoreFiltersOptions;
  handleMFAssignedToChange: (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteOptionsType[]) => void;
  handleMFTagsChange: (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteOptionsType[]) => void;
}
const MoreFiltersDialog = ({ mfo, handleMFAssignedToChange, handleMFTagsChange }: MoreFiltersDialogProps) => {

  const handleClose = () => {
    // Closing the dialog without APPLYING the changes made inside the dialog, so reset to original values
    mfo.setSelectedAssignedTo(mfo.origSelectedAssignedTo);
    mfo.setSelectedTags(mfo.origSelectedTags);
    mfo.setSelectedDateRange(mfo.origSelectedDateRange);
    mfo.setMoreFiltersIsOpen(false);
  };

  const handleApplyButtonClick = () => {
    // Closing the dialog with the APPLY button, so update the original values to match the changes made inside the dialog
    mfo.setOrigSelectedAssignedTo(mfo.selectedAssignedTo);
    mfo.setOrigSelectedTags(mfo.selectedTags);
    mfo.setOrigSelectedDateRange(mfo.selectedDateRange);
    mfo.setIsMoreFiltering(true);
  };

  return (
    <Dialog
      open={mfo.moreFiltersIsOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          width: 528
        }
      }}
      aria-labelledby='more-filters-modal-title'
      aria-describedby='more-filters-modal-content'
    >
      <DialogTitle
        id='more-filters-modal-title'
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          pl: 3
        }}
      >Apply Additional Filters
        <IconButton aria-label='Close' onClick={handleClose} sx={{ ml: 'auto' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent id='more-filters-modal-content'>
        <DateRange 
          selectedDateRange={mfo.selectedDateRange}
          setSelectedDateRange={mfo.setSelectedDateRange}
        />
        <MoreFiltersAutocomplete
          ariaSuffix='assigned-to'
          autoFocus
          label='Assigned To'
          limitTags={3}
          options={mfo.assignedToData}
          noOptionsText='No assigned to entries found'
          sx={{
            mb: 2.75,
            pt: 2.5
          }}
          handleAutoCompleteChange={handleMFAssignedToChange}
          value={mfo.selectedAssignedTo}
        />
        <MoreFiltersAutocomplete
          ariaSuffix='tags'
          label='Tags'
          limitTags={4}
          options={mfo.tags}
          noOptionsText='No tags found'
          handleAutoCompleteChange={handleMFTagsChange}
          value={mfo.selectedTags}
        />
      </DialogContent>

      <DialogActions sx={{
        color: 'InceptiaGreen.main',
        justifyContent: 'center',
        p: 3,
        pb: 2.5
      }}>
        <Button
          variant='outlined'
          color='inherit'
          disabled={mfo.isMoreFiltering ? mfo.isMoreFiltering : false}
          sx={{
            flexBasis: '50%',
            textTransform: 'none',
            '&:hover, &:focus': {
              backgroundColor: inceptiaGreenAlphaColors.hover
            }
          }}
          onClick={handleClose}
        >Cancel</Button>
        <Button 
          variant='contained'
          disabled={mfo.isMoreFiltering ? mfo.isMoreFiltering : false}
          sx={{
            flexBasis: '50%',
            textTransform: 'none',
            bgcolor: 'InceptiaGreen.main',
            '&:hover': {
              backgroundColor: 'InceptiaGreen.dark',
            }
          }}
          onClick={handleApplyButtonClick}
        >
          {mfo.isMoreFiltering ? (
            <>
              <CircularProgress
                color='inherit'
                size={20}
                sx={{ mr: 1.5 }}
              />
              Applying...
            </>
          ) : 'Apply'}</Button>
      </DialogActions>
    </Dialog>
  );
};

type ActionDialogProps = {
  name: string;
  title: string;
  selectedSubmissions: iAdminFormSubmissionsData[] | null | undefined;
  adminFormSubmssionsDataOptions: AdminFormSubmissionsDataOptionsType;
  setForceRefresh: (value: boolean) => void;
  dialogIsOpen: boolean;
  setDialogIsOpen: (value: boolean) => void;
  getOptions: () => { label: string | undefined; value: string; }[];
  singleSaveAction?: (selectedSubmissionsUuids: string[], selectedValue:any, loader: (v: boolean) => void) => Promise<Response>;
  multiple: boolean;
  multipleSaveAction?: (formUuid: string | null, selectedSubmissionsUuids: string[], selectedValues: any[], allowDelete: boolean, loader: (v: boolean) => void) => Promise<Response>;
  sucessMessage: string;
  restOfTheMessage: string;
  confirmActionArray?: any[];
  confirmActionMessage?: string;
}
const ActionDialog = (props : ActionDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);  
  const [confimed, setConfirmed] = useState(false);  
  const[ selectedValue, setSelectedValue] = useState<any>()

  useEffect(() => {
    const SingleSave = async () => {
      if (props.selectedSubmissions?.length == 0) {
        enqueueSnackbar('No submissions selected.', { variant: 'error' });
        setIsWorking(false);
        props.setDialogIsOpen(false);
      } else if (selectedValue == null) {
        enqueueSnackbar(`No ${props.name} selected.`, { variant: 'error' });
        setIsWorking(false)
      } else {
        let uuids = props.selectedSubmissions?.map(submission => submission.uuid) || []
        if (props.singleSaveAction != null) {
          await props.singleSaveAction(uuids, selectedValue.value, (v) => v).then((result) => {
            if (result.status == 200) {
              enqueueSnackbar(props.sucessMessage, { variant: 'success' });
            }
          }).catch(error => {
            enqueueSnackbar('Error saving.', { variant: 'error' });
          }).finally(() => {
            setIsWorking(false);
            props.setForceRefresh(true);
            props.setDialogIsOpen(false)
          });
        } else {
          enqueueSnackbar('Error saving.', { variant: 'error' });
          setIsWorking(false);
          props.setDialogIsOpen(false);
        }
      }
    }
      const MultipleSave = async () => {
        if (props.selectedSubmissions?.length == 0) {
          enqueueSnackbar('No submissions selected.', { variant: 'error' });
          setIsWorking(false);
          props.setDialogIsOpen(false);
        } else if (selectedValue == null) {
          enqueueSnackbar(`No ${props.name} selected.`, { variant: 'error' });
          setIsWorking(false)
        } else {
          let ids = props.selectedSubmissions?.map(submission => submission.uuid) || [];
          let formUuid = props.selectedSubmissions ? props.selectedSubmissions[0].formUuid : null;
          let selectedValueIds = selectedValue.map((v: { value: string; }) => v.value);
          if (props.multipleSaveAction != null) {
            await props.multipleSaveAction(formUuid, ids, selectedValueIds, false, (v) => v).then((result) => {
              if (result.status == 200) {
                enqueueSnackbar(props.sucessMessage, { variant: 'success' });
              }
            }).catch(error => {
              enqueueSnackbar('Error saving.', { variant: 'error' });
            }).finally(() => {
              setIsWorking(false);
              props.setForceRefresh(true);
              props.setDialogIsOpen(false)
            });
          } else {
            enqueueSnackbar('Error saving.', { variant: 'error' });
            setIsWorking(false);
            props.setDialogIsOpen(false);
          }
        }
      }
      if (isWorking) {
        let needsConfirmation = false;
        if(props.confirmActionArray){
          
          props.confirmActionArray.forEach(element => {
            if (element == selectedValue.value) {
              needsConfirmation = true;
            }
          });
        }
        if (needsConfirmation && !confimed) {
          setShowConfirmMessage(true);
          setConfirmed(true);
          setIsWorking(false);
        } else {
          if (props.multiple) {
            MultipleSave();
          } else {
            SingleSave()
          }
        }
      }
    }
  , [isWorking])

  return (
    <ConfirmationDialog ariaPrefix={props.name} dialogTitle={props.title} singleButton={false} ctaButtonText={'Apply'} ctaButtonWorkingText={'Applying...'} open={props.dialogIsOpen} 
    setOpenDialog={props.setDialogIsOpen}  
    isWorking = {isWorking} 
    setIsWorking={setIsWorking}>
      {!showConfirmMessage && <Autocomplete
        id={`change-${props.name}-autocomplete`}
        onChange={(e, value:any) => {
          setSelectedValue(value);
        }}
        size='small'
        renderInput={(params) => <TextField
          {...params}
          autoFocus={true}
          label={props.name} />}
        // Style the selected items in the dropdown list
        slotProps={{
          popper: {
            sx: {
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: inceptiaGreenAlphaColors.hover
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: inceptiaGreenAlphaColors.selected
              },
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                backgroundColor: inceptiaGreenAlphaColors.selectedHover
              }
            }
          }
        }}
        sx={{marginTop: '1rem'}}
        multiple={props.multiple}
        options={props.getOptions()}
        disableClearable={true}
      />}
      {!showConfirmMessage && <Typography variant='body2' sx={{ mb: 2, marginTop: '1rem' }}>This will change <b>{props.selectedSubmissions?.length == 1 ? "1 selected submission": props.selectedSubmissions?.length + " selected submissions"}</b> and {props.restOfTheMessage}. </Typography>}
      {showConfirmMessage && <Typography variant='body2' sx={{ mb: 2, marginTop: '1rem' }}>{props.confirmActionMessage} <b>{selectedValue.label}</b>?</Typography>} 
    </ConfirmationDialog>
  );
}

type TableWrapperProps = {
  handleRequestSort: (e: React.MouseEvent<unknown>, property: keyof iAdminFormSubmissionsData) => void;
  sortField: string;
  sortDir: Order;
  selectedStudentCount: number;
  rowData: iAdminFormSubmissionsData[];
  handleCheckAllToggle: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  children: React.ReactNode;
};
const TableWrapper = (props: TableWrapperProps) => {
  const { handleRequestSort, sortField, sortDir, selectedStudentCount, rowData, handleCheckAllToggle, children } = props;

  const thSX = {
    borderColor: '#d9d9d9',
    color: '#1e1e1e',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',

    // Keyboard accessibility tweak
    '& .Mui-focusVisible:not(.MuiCheckbox-root)': {
      outline: '1px solid',
      outlineOffset: '5px'
    }
  };

  interface HeadCell {
    id: keyof iAdminFormSubmissionsData;
    label: string;
    align: 'left' | 'center' | 'right';
    sortable: boolean;
  };
  const headCells: readonly HeadCell[] = [
    { id: 'name', label: 'Name', align: 'left', sortable: true },
    // FEBS-821 Hiding or Removing the DOB column on the Forms Summary page
    // { id: 'dob', label: 'DOB', align: 'left', sortable: false },

    // FEBS-906 - Adding phone number under email and changing column header to "Contact"
    { id: 'email', label: 'Contact', align: 'left', sortable: false },

    { id: 'attachments', label: 'Attachments', align: 'center', sortable: false },
    { id: 'lastUpdated', label: 'Last Updated', align: 'left', sortable: true },
    { id: 'assignee', label: 'Assigned To', align: 'left', sortable: true },
    { id: 'tags', label: 'Tags', align: 'left', sortable: true },
    // Status for the column header, but want to actually sort on the submitted date value that's also in this columnn
    { id: 'submittedDate', label: 'Status', align: 'left', sortable: true }
  ];

  const createSortHandler = (property: keyof iAdminFormSubmissionsData) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table aria-label='admin form summary submissions table' size='small'>
        <TableHead>
          <TableRow sx={{ textTransform: 'uppercase' }}>
            <TableCell padding='checkbox' sx={{...thSX, color: 'InceptiaGreen.main' }}>
              <Typography component='label' htmlFor='select-all-students' sx={visuallyHidden}>Select all students</Typography>
              <Checkbox
                indeterminate={selectedStudentCount > 0 && selectedStudentCount < rowData.length}
                checked={rowData.length > 0 && selectedStudentCount === rowData.length}
                onChange={handleCheckAllToggle}
                inputProps={{
                  'aria-label': 'Toggle selection of student in the table',
                  id: 'select-all-students',
                  title: 'Check/uncheck all'
                }}
              />
            </TableCell>

            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                // The sortDirection prop will write an aria-sort attribute to the <th> element
                {...(headCell.sortable ? { sortDirection: sortField === headCell.id ? sortDir : false } : {})}
                sx={thSX}
              >{headCell.sortable ? (
                <TableSortLabel
                  active={sortField === headCell.id}
                  direction={sortField === headCell.id ? sortDir : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {sortField === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableLoader = ({ colsNum, rowsNum, animation = 'pulse' }: TableLoaderProps) => {
  return (
    <TableWrapper
      // !!! Feed all these props dummy values since it doesn't really matter for a loader
      sortField={'submitted_Date'}
      sortDir={'asc'}
      handleRequestSort={() => {}}
      selectedStudentCount={0}
      rowData={[]}
      handleCheckAllToggle={() => {}}
    >
      <TableRowsLoader colsNum={colsNum} rowsNum={rowsNum} animation={animation} rowHeight={55} />
    </TableWrapper>
  );
};

type AdminFormSubmissionsTableProps = {
  disableChangeOptions: boolean;
  searchQuery: string;
}
export default function AdminFormSubmissionsTable({ disableChangeOptions, searchQuery }: AdminFormSubmissionsTableProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isActionsMenuOpen = Boolean(anchorEl);

  const {
    adminFormSubmissionsData, setAdminFormSubmissionsData,
    isAdminFormSubmissionsDataLoaded, setIsAdminFormSubmissionsDataLoaded,
    adminFormSubmissionsDataOptions, setAdminFormSubmissionsDataOptions,
    setForceRefresh,
    adminFormSubmissionsDataTotalCount
  } = useContext(AdminFormSubmissionsDataContext);

  const [sortDir, setSortDir] = useState<Order>('asc');
  const [sortField, setSortField] = useState<keyof iAdminFormSubmissionsData>('submittedDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [selectedStudentCount, setSelectedStudentCount] = useState(0);

  const [moreFiltersCount, setMoreFiltersCount] = useState(0);
  const [moreFiltersIsOpen, setMoreFiltersIsOpen] = useState(false);
  const [changeStatusIsOpen, setchangeStatusIsOpen] = useState(false);
  const [changeAssigneeIsOpen, setChangeAssigneeIsOpen] = useState(false);
  const [changeTagsIsOpen, setChangeTagsIsOpen] = useState(false);
  const [sendNotificationsIsOpen, setSendNotificationsIsOpen] = useState(false);
  const [isMoreFiltering, setIsMoreFiltering] = useState(false);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<AutoCompleteOptionsType[]>([]);
  const [selectedTags, setSelectedTags] = useState<AutoCompleteOptionsType[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState(['']);
  const [origSelectedDateRange, setOrigSelectedDateRange] = useState(['']);
  const [origSelectedAssignedTo, setOrigSelectedAssignedTo] = useState<AutoCompleteOptionsType[]>(selectedAssignedTo);
  const [origSelectedTags, setOrigSelectedTags] = useState<AutoCompleteOptionsType[]>(selectedTags);
  const statusesForConfirmModal = [Status.APPROVED, Status.DECLINED];

  const [assignedToData, setAssignedToData] = useState<AutoCompleteOptionsType[]>([]);
  const [tags, setTags] = useState<AutoCompleteOptionsType[]>([]);
  const { organization } = useContext(OrganizationContext);

  const moreFiltersOptions: MoreFiltersOptions = {
    moreFiltersIsOpen,
    isMoreFiltering,
    assignedToData,
    setAssignedToData,
    selectedAssignedTo,
    tags,
    setTags,
    selectedTags,
    selectedDateRange,
    origSelectedAssignedTo,
    origSelectedTags,
    origSelectedDateRange,
    setMoreFiltersIsOpen,
    setIsMoreFiltering,
    setSelectedAssignedTo,
    setSelectedTags,
    setSelectedDateRange,
    setOrigSelectedAssignedTo,
    setOrigSelectedTags,
    setOrigSelectedDateRange
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - adminFormSubmissionsDataTotalCount) : 0;

  const allFilteredRows: iAdminFormSubmissionsData[] = useMemo(() => 
    adminFormSubmissionsData?.length ? adminFormSubmissionsData : []
  , [adminFormSubmissionsData]);

  useEffect(() => {
    if (adminFormSubmissionsData?.length) setSelectedStudentCount(adminFormSubmissionsData.filter(row => row.selected).length);
  }, [adminFormSubmissionsData]);

  useEffect(() => {
    (async() => {
      let options: AutoCompleteOptionsType[] = [];
      adminFormsService.GetFormAssignees(adminFormSubmissionsDataOptions.formUuid, (v)=>{}).then(result => {
        result.forEach((user: iUserDTO) => {
          options.push({ label: user.first_Name + " " + user.last_Name, value: user.auth_Id })
        });
        options.sort((a, b) => a.label.localeCompare(b.label));
        setAssignedToData(options);
      });
    })();
  }, [adminFormSubmissionsDataOptions.formUuid]);

  useEffect(() => {
    (async() => {
      let options: AutoCompleteOptionsType[] = [];
      tagService.GetFormTags(adminFormSubmissionsDataOptions.formUuid, (v)=>{}).then(result => {
        result.forEach((tag: iFormTag) => {
          options.push({ label: tag.tag_Name, value: tag.id.toString() })
        });
        options.sort((a, b) => a.label.localeCompare(b.label));
        setTags(options);
      });
    })();
  }, [adminFormSubmissionsDataOptions.formUuid]);

  useEffect(() => {
    const ApplyMoreFiltersAsync = async () => {
      // Will cause the "loading" state to display
      setIsAdminFormSubmissionsDataLoaded(false);

      // Setting the state for the data options will result in an API call
      const newAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = {
        ...adminFormSubmissionsDataOptions,
        assignedToUuids: selectedAssignedTo.map(item => item.value),
        tags: selectedTags.map(item => item.value),
        dateRange: selectedDateRange
      };
      setAdminFormSubmissionsDataOptions(newAdminFormSubmissionsDataOptions);

      setIsMoreFiltering(false);
      setMoreFiltersIsOpen(false);
      const dateCount = (selectedDateRange.length  && selectedDateRange[0] !== '' ? 1 : 0)
      setMoreFiltersCount(selectedAssignedTo.length + selectedTags.length + dateCount);
    };
  
    if (isMoreFiltering) ApplyMoreFiltersAsync();
  }, [isMoreFiltering]);

  const handleActionsMenuItemClick = (e: React.MouseEvent<HTMLLIElement>) => {

    // Need to know which menu item was clicked (export or status)
    const action = (e.target as HTMLElement).closest('li')?.getAttribute('data-menu-action')?.toLowerCase();
    switch (action) {
      case 'export':
        handleExportCsv(adminFormSubmissionsDataOptions);
        break;
      case 'status':
        setchangeStatusIsOpen(true);
        break;
      case 'assignee':
        setChangeAssigneeIsOpen(true)
        break;
      case 'tags':
        setChangeTagsIsOpen(true)
        break;
      case 'notifications':
        setSendNotificationsIsOpen(true);
        break;
    }

    setAnchorEl(null);
  };

  const handleRequestSort = (e: React.MouseEvent<unknown>, property: keyof iAdminFormSubmissionsData) => {
    // If the column clicked on is already sorted in asscending order, set the flag - which will result in a descending sort
    const isAsc = (sortField === property && sortDir === 'asc');

    // Will cause the "loading" state to display
    setIsAdminFormSubmissionsDataLoaded(false);

    // Setting the state for the data options will result in an API call
    const newAdminFormSubmissionsDataOptions: AdminFormSubmissionsDataOptionsType = {
      ...adminFormSubmissionsDataOptions,
      sortField: property,
      sortDirection: isAsc ? 1 : 0
    };
    setAdminFormSubmissionsDataOptions(newAdminFormSubmissionsDataOptions);

    setSortDir(isAsc ? 'desc' : 'asc');
    setSortField(property);
  };

  const handleCheckAllToggle = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newAdminFormSubmissionsData : iAdminFormSubmissionsData[] = adminFormSubmissionsData!.map(submission => {
      return {
        ...submission,
        selected: checked
      };
    });
    setAdminFormSubmissionsData(newAdminFormSubmissionsData);
  };

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    // stopping the click through to the row event since we want to handle row clicks as navigation
    e.stopPropagation();
    const newAdminFormSubmissionsData : iAdminFormSubmissionsData[] = adminFormSubmissionsData!.map(submission => {
      if (submission.uuid === value) {
        return {
          ...submission,
          selected: !submission.selected
        }
      } else {
        return submission;
      }
    });
    setAdminFormSubmissionsData(newAdminFormSubmissionsData);
  };

  const handlePageChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);

    setIsAdminFormSubmissionsDataLoaded(false);
    setAdminFormSubmissionsDataOptions({
      ...adminFormSubmissionsDataOptions,
      page: newPage
    });
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    setIsAdminFormSubmissionsDataLoaded(false);
    setAdminFormSubmissionsDataOptions({
      ...adminFormSubmissionsDataOptions,
      rowsPerPage: newRowsPerPage,
      page: 0
    });
  };

  const handleMFAssignedToChange = (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteOptionsType[] ) => {
    setSelectedAssignedTo(value);
  };

  const handleMFTagsChange = (e: React.SyntheticEvent<Element, Event>, value: AutoCompleteOptionsType[] ) => {
    setSelectedTags(value);
  };

  const handleExportCsv = async (options: AdminFormSubmissionsDataOptionsType) => {
    const exportOptions = {...options};
    exportOptions.rowsPerPage = 0;
    const selectedRows = adminFormSubmissionsData?.filter(s => s.selected);
    // Export selected rows, if any, or all rows (without paging)
    const formData = selectedRows?.length ? 
        selectedRows
        : (await adminFormsService.GetFormSubmissionsAndStatusTotals(exportOptions, (_) => {})).submissions;

    downloadCsv(createCsvContent(mapToExportData(formData)), csvFileName);
  };

  const mapToExportData = (data: iAdminFormSubmissionsData[]) => {
    return data.map((row: iAdminFormSubmissionsData) => {  
      return {
        Name: `${row.firstName} ${row.lastName}`,
        SSN: formatSSN(row.last4 || '', true),
        Student_ID: row.schoolStudentId,
        DOB: formatDate(row.dob),
        Email: row.email,
        Attachments: row.attachments,
        Last_Updated: formatDate(row.lastUpdated),
        Assigned_To: row.assignee?.first_Name? 
          `${row.assignee!.first_Name} ${row.assignee!.last_Name}`
          : 'Unassigned',
        Tags: row.tags.map((tag) => tag.tag_Name).join(' '),
        Status: StatusLabel.get(row.statusId),
        Submitted_Date: formatDate(row.submittedDate)
      };
    })
  }

  const tdSX = {
    borderColor: '#d9d9d9',
    fontSize: '0.75rem',
    py: 1.125
  };

  return (
    <Container sx={{py:3}}>
      {/* Results (table row count) and Actions button */}
      <Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
        <Typography variant='body2' sx={{ color: '#000' }}>
          Results:
          {!isAdminFormSubmissionsDataLoaded ? ' ...' : ` ${adminFormSubmissionsDataTotalCount.toLocaleString()}`}
        </Typography>
        <Button
          color='inherit'
          variant='outlined'
          onClick={() => setMoreFiltersIsOpen(true)}
          sx={{
            fontSize: '1rem',
            ml: 'auto',
            mr: 1.25,
            textTransform: 'none'
          }}
        >
          More Filters
          {moreFiltersCount > 0 && (
            <Typography
              component='span'
              sx={{
                backgroundColor: 'InceptiaGreen.main',
                color: 'InceptiaGreen.contrastText',
                borderRadius: '100vw',
                fontSize: '0.75rem',
                ml: 1,
                px: 0.875,
                py: 0.125
              }}
            >{moreFiltersCount}</Typography>
          )}
        </Button>
        <ActionsButton
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isActionsMenuOpen={isActionsMenuOpen}
          handleActionsMenuItemClick={handleActionsMenuItemClick}
          disableChangeOptions={disableChangeOptions}
        />
      </Stack>

      {/* Loading... */}
      { ! isAdminFormSubmissionsDataLoaded && <TableLoader colsNum={9} rowsNum={adminFormSubmissionsData?.length || 10} />}

      {/* Loaded, but no submissions returned from the API */}
      {isAdminFormSubmissionsDataLoaded && adminFormSubmissionsData?.length === 0 && (
         <TableWrapper
          sortField={sortField}
          sortDir={sortDir}
          handleRequestSort={handleRequestSort}
          selectedStudentCount={selectedStudentCount}
          rowData={adminFormSubmissionsData}
          handleCheckAllToggle={handleCheckAllToggle}
         >
          <TableRow>
            <TableCell colSpan={9} sx={{ borderBottom: 'none' }}>
              <Typography
                variant='body1'
                sx={{
                  // WAVE accessibility contrast error if color matches Figma color of #b8b8b8, so darken it enough to pass (grey.600 = #757575)
                  color: 'grey.600',
                  fontWeight: 500,
                  py: 3.75,
                  textAlign: 'center'
                }}
              >No submissions found...</Typography>
            </TableCell>
          </TableRow>
        </TableWrapper>
      )}

      {/* Loaded, with submissions returned from the API */}
      {isAdminFormSubmissionsDataLoaded && (adminFormSubmissionsData || []).length > 0 && (
        <TableWrapper
          sortField={sortField}
          sortDir={sortDir}
          handleRequestSort={handleRequestSort}
          selectedStudentCount={selectedStudentCount}
          rowData={adminFormSubmissionsData || []}
          handleCheckAllToggle={handleCheckAllToggle}
        >
          {adminFormSubmissionsData?.map(row => {
            const labelId = `checkbox-list-label-${row.uuid}`;

            return (
              <TableRow
                key={row.uuid}
                hover
                role='button' // Keyboard accessibility
                tabIndex={0} // Keyboard accessibility
                selected={row.selected}
                onClick={() => navigate(`/admin/form/${location.pathname.split('/').pop()}/applicant/${row.authId}/${row.uuid}`)}
                onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
                  // Keyboard accessibility - open submission when pressing enter or space
                  if (e.key === 'Enter' || e.key === ' ') {
                    navigate(`/admin/form/${location.pathname.split('/').pop()}/applicant/${row.authId}/${row.uuid}`)
                  }
                }}
                sx={{
                  borderColor: 'grey.100',
                  cursor: 'pointer',
                  '&.Mui-selected, &.Mui-selected:hover': {
                    backgroundColor: inceptiaGreenAlphaColors.selected
                  },
                  '&:last-child > td': { border: 0 },
                  '&:focus-visible': { backgroundColor: inceptiaGreenAlphaColors.hover }
                }}
              >
                <TableCell padding='checkbox' sx={{ ...tdSX, color: 'InceptiaGreen.main' }}>
                  <Typography component='label' htmlFor={labelId} sx={visuallyHidden}>Select all students</Typography>
                  <Checkbox
                    checked={row.selected || false}
                    onClick={event => handleToggle(event, row.uuid)}
                    inputProps={{
                      'aria-label': 'Select student',
                      'id': labelId
                    }}
                  />
                </TableCell>
                <TableCell sx={{ ...tdSX, minWidth: '150px', wordBreak: 'break-word' }}>
                  <div><HighlightedText text={row.name || ''} highlight={searchQuery} /></div>
                  <div><HighlightedText text={formatSSN(row.last4 || '', true)} highlight={searchQuery} /></div>
                  <div><HighlightedText text={row.schoolStudentId || ''} highlight={searchQuery} /></div>
                </TableCell>
                {/* FEBS-821 Hiding or Removing the DOB column on the Forms Summary page */}
                {/* <TableCell sx={tdSX}>{formatDate(row.dob)}</TableCell> */}
                <TableCell sx={{ ...tdSX, minWidth: '200px', wordBreak: 'break-word' }}>
                  <div><HighlightedText text={row.email || ''} highlight={searchQuery} /></div>
                  <div><HighlightedText text={formatPhoneNumber(row.phone || '')} highlight={searchQuery} ignoreChars='()- ' /></div>
                </TableCell>
                <TableCell sx={{ ...tdSX, textAlign: 'center' }}>{row.attachments}</TableCell>
                <TableCell sx={tdSX}>{formatDate(row.lastUpdated)}</TableCell>
                <TableCell sx={tdSX}>{row.assigneeUuid ==null ? "Unassigned" : row.assignee?.first_Name +" "+ row.assignee?.last_Name}</TableCell>
                <TableCell sx={tdSX}>
                  {row.tags && row.tags.map((tag, index) => (
                    <Typography
                      key={index}
                      sx={{ display: 'inline-block', fontSize: '0.75rem' }}
                    >
                      {tag.tag_Name}{index < row.tags.length - 1 ? ', ' : null}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell sx={tdSX}>
                  <div>{StatusLabel.get(row.statusId)}</div>
                  {formatDate(row.submittedDate)}
                </TableCell>
              </TableRow>
            )}
          )}
          {emptyRows > 0 && (
            <TableRow sx={{ height: 70 * emptyRows }}>
              <TableCell colSpan={9} />
            </TableRow>
          )}
        </TableWrapper>
      )}

      {isAdminFormSubmissionsDataLoaded && (
        <TablePagination
          component='div'
          count={adminFormSubmissionsDataTotalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[50, 100, 250, 500]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{
            '&, & .MuiTablePagination-input .MuiSvgIcon-root': { color: '#000' }
          }}
          slotProps={{
            actions: {
              previousButton: { 'aria-label': 'previous page' },
              nextButton: { 'aria-label': 'next page' }
            },
            // MUI generates WAVE "Missing form label" error, the options below fix it
            // https://stackoverflow.com/questions/66642553/tablepagination-mui-missing-form-label-accessibility-issue-in-wave-tool
            select: {
              id: 'rows-per-page-select',
              inputProps: {
                'aria-label': 'rows per page',
                'aria-labelledby': 'rows-per-page-select'
              }
            }
          }}
        />
      )}

      {moreFiltersIsOpen && <MoreFiltersDialog
        mfo={moreFiltersOptions}
        handleMFAssignedToChange={handleMFAssignedToChange}
        handleMFTagsChange={handleMFTagsChange}
      />}
      {
        changeStatusIsOpen && 
        <ActionDialog 
          multiple={false}
          name='Status'
          title='Change Status'
          selectedSubmissions={adminFormSubmissionsData?.filter((submission) => {return submission.selected})}
          dialogIsOpen={changeStatusIsOpen}
          setDialogIsOpen={setchangeStatusIsOpen}
          sucessMessage='Status changed.'
          getOptions={() => {
            let validStatuses = [Status.APPROVED,Status.IN_REVIEW,Status.DECLINED,Status.ACTION_NEEDED,Status.SUSPENDED,Status.PENDING_FINAL_REVIEW]
            let options: { label: string | undefined; value: string ; }[] = [];
            validStatuses.forEach((status) => {
              options.push({label:StatusLabel.get(status),value:status.toString()})
            });
            return options;
          }}
          singleSaveAction={adminFormsService.UpdateSubmissionStatuses}
          adminFormSubmssionsDataOptions={adminFormSubmissionsDataOptions}
          setForceRefresh={setForceRefresh}
          restOfTheMessage='trigger any relevant notifications.'
          confirmActionArray={statusesForConfirmModal}
          confirmActionMessage= {`Are you sure you want to change the status to `}
        />
      }
      {
        changeAssigneeIsOpen && <ActionDialog
          multiple={false}
          name='Assignee'
          title='Change Assignee'
          dialogIsOpen={changeAssigneeIsOpen}
          setDialogIsOpen={setChangeAssigneeIsOpen}
          sucessMessage='Assignee changed.'
          selectedSubmissions={adminFormSubmissionsData?.filter((submission)=> {return submission.selected})}
          getOptions={() => {
            let options: { label: string | undefined; value: string; }[] = [];
            adminFormsService.GetFormAssignees(adminFormSubmissionsDataOptions.formUuid, (v)=>{}).then((result) => {
              result.forEach((user: iUserDTO) => {
                options.push({ label: user.first_Name + " " + user.last_Name, value: user.auth_Id })
              });
              
            });
            return options;
          }}
          singleSaveAction={adminFormsService.UpdateSubmisionAssignee}
          adminFormSubmssionsDataOptions={adminFormSubmissionsDataOptions}
          setForceRefresh={setForceRefresh}
          restOfTheMessage='trigger any relevant notifications.'
        />
      }
      {
        changeTagsIsOpen && <ActionDialog
          multiple={true}
          name='Tags'
          title='Change Tags'
          dialogIsOpen={changeTagsIsOpen}
          setDialogIsOpen={setChangeTagsIsOpen}
          sucessMessage='Tags changed.'
          selectedSubmissions={adminFormSubmissionsData?.filter((submission) => { return submission.selected })}
          getOptions={() => {
            let options: { label: string | undefined; value: string; }[] = [];
            tagService.GetFormTags(adminFormSubmissionsDataOptions.formUuid, (v)=>{}).then((result) => {
              result.forEach((tag: iFormTag) => {
                options.push({ label: tag.tag_Name, value: tag.id.toString() })
              });
            }
            );
            return options;
          }}
          multipleSaveAction={tagService.SetSubmissionsTags}
          adminFormSubmssionsDataOptions={adminFormSubmissionsDataOptions}
          setForceRefresh={setForceRefresh}
          restOfTheMessage='replace any existing tags.'
        />
      }
      {
        sendNotificationsIsOpen && <NotificationForm 
          isOpenDialog={sendNotificationsIsOpen}
          setIsOpenDialog={setSendNotificationsIsOpen}             
          users={adminFormSubmissionsData?.filter(x => x.selected)?.map(x => ({ 
            user_id: x.submittedBy, 
            submission_form_id: x.id, 
            email_address: x.email, 
            phone_number: x.phone, 
            organization: organization })) ?? []} />
      }
    </Container>
  );
}
