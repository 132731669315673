import React from 'react';
import { Grid, TextField, Typography, TableCell, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as yup from 'yup';
import notificationService from '../../services/NotificationService';
import { NotificationScheduleDto, NotificationScheduleDtoIntervalTypeEnum } from '../../apis/notifications';
import ManageCrudPage, { DropdownStyles } from './ManageCrudPage';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface NotificationScheduleCrudProps {
    isInceptiaUser: boolean;
}

const NotificationScheduleCrud = ({ isInceptiaUser = false }: NotificationScheduleCrudProps) => {
    const initialValues: {
        start_date: Date;
        end_date: Date;
        send_time: string;
        timezone: string;
    } = {
        start_date: new Date(),
        end_date: new Date(),
        send_time: '',
        timezone: '',
    };

    const validationSchema = yup.object().shape({
        start_date: yup.date().required('Start date is required'),
        end_date: yup.date().required('End date is required'),
        send_time: yup.string().required('Send time is required'),
        timezone: yup.string().required('Timezone is required'),
        interval_type: yup.string().oneOf(Object.values(NotificationScheduleDtoIntervalTypeEnum)).notRequired(),
        interval_value: yup.number().min(1).notRequired(),
        specific_days_of_week: yup.array().of(yup.number()).notRequired(),
        specific_days_of_month: yup.array().of(yup.number()).notRequired(),
        max_notification_attempts: yup.number().nullable().notRequired(),
    });

    let formatDate = (date: Date | string | undefined | null) => {
        return date ? new Date(date) : undefined;
    };

    const renderFormFields = (formik: any) => (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="Start Date"
                            value={formatDate(formik.values.start_date)}
                            onChange={(value) => formik.setFieldValue('start_date', value || null)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: formik.touched.start_date && Boolean(formik.errors.start_date),
                                    helperText: formik.touched.start_date && formik.errors.start_date,
                                    InputProps: {
                                        className: isInceptiaUser ? undefined : "Mui-disabled"
                                    },
                                    inputProps: {
                                        readOnly: !isInceptiaUser
                                    }
                                },
                            }}
                            disabled={isInceptiaUser ? undefined : true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label="End Date"
                            value={formatDate(formik.values.end_date)}
                            onChange={(value) => formik.setFieldValue('end_date', value || null)}
                            disabled={isInceptiaUser ? undefined : true}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: formik.touched.end_date && Boolean(formik.errors.end_date),
                                    helperText: formik.touched.end_date && formik.errors.end_date,
                                    InputProps: {
                                        className: isInceptiaUser ? undefined : "Mui-disabled"
                                    },
                                    inputProps: {
                                        readOnly: !isInceptiaUser
                                    }
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TimePicker
                            label="Send Time"
                            value={formik.values.send_time ? 
                                new Date(new Date().toLocaleDateString() + ' ' + formik.values.send_time) : undefined}
                            onChange={(value) => formik.setFieldValue('send_time', value ? new Date(value.toString() + "Z") :  null)}
                            disabled={isInceptiaUser ? undefined : true}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: formik.touched.send_time && Boolean(formik.errors.send_time),
                                    helperText: formik.touched.send_time && formik.errors.send_time,
                                    InputProps: {
                                        className: isInceptiaUser ? undefined : "Mui-disabled"
                                    },
                                    inputProps: {
                                        readOnly: !isInceptiaUser
                                    }
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="timezone"
                            label="Timezone"
                            fullWidth
                            value={formik.values.timezone}
                            onChange={formik.handleChange}
                            error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                            helperText={formik.touched.timezone && formik.errors.timezone}
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth error={formik.touched.interval_type && Boolean(formik.errors.interval_type)} sx={DropdownStyles}>
                            <InputLabel id="interval-type-label">Interval Type</InputLabel>
                            <Select
                                labelId="interval-type-label"
                                id="interval_type"
                                name="interval_type"
                                value={formik.values.interval_type}
                                onChange={formik.handleChange}
                                disabled={isInceptiaUser ? undefined : true}
                            >
                                {Object.values(NotificationScheduleDtoIntervalTypeEnum).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formik.touched.interval_type && formik.errors.interval_type ? (
                                <Typography variant="caption" color="error">{String(formik.errors.interval_type)}</Typography>
                            ) : null}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="interval_value"
                            label="Interval Value"
                            type="number"
                            fullWidth
                            value={formik.values.interval_value}
                            onChange={formik.handleChange}
                            error={formik.touched.interval_value && Boolean(formik.errors.interval_value)}
                            helperText={formik.touched.interval_value && formik.errors.interval_value}
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="specific_days_of_month"
                            label="Specific Days of Month"
                            fullWidth
                            value={formik.values.specific_days_of_month?.join(', ')}
                            onChange={(e) => formik.setFieldValue('specific_days_of_month', e.target.value.split(',').map(Number))}
                            helperText="Enter comma-separated values for specific days (e.g., 1, 15, 30)"
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="specific_days_of_week"
                            label="Specific Days of Week"
                            fullWidth
                            value={formik.values.specific_days_of_week?.join(', ')}
                            onChange={(e) => formik.setFieldValue('specific_days_of_week', e.target.value.split(',').map(Number))}
                            helperText="Enter comma-separated values for specific days of the week (e.g., 0 for Sunday, 1 for Monday)"
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="max_notification_attempts"
                            label="Max Notification Attempts"
                            type="number"
                            fullWidth
                            value={formik.values.max_notification_attempts || ''}
                            onChange={formik.handleChange}
                            error={formik.touched.max_notification_attempts && Boolean(formik.errors.max_notification_attempts)}
                            helperText={formik.touched.max_notification_attempts && formik.errors.max_notification_attempts}
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    );

    const renderTableHeaders = () => (
        <>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Send Time</TableCell>
            <TableCell>Timezone</TableCell>
            <TableCell>Interval Type</TableCell>
            <TableCell>Interval Value</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationScheduleDto) => (
        <>
            <TableCell>{new Date(item.start_date).toLocaleDateString()}</TableCell>
            <TableCell>{new Date(item.end_date).toLocaleDateString()}</TableCell>
            <TableCell>{item.send_time}</TableCell>
            <TableCell>{item.timezone}</TableCell>
            <TableCell>{item.interval_type}</TableCell>
            <TableCell>{item.interval_value}</TableCell>
        </>
    );

    return (
        <ManageCrudPage<NotificationScheduleDto>
            title="Manage Notification Schedules"
            entityName="Schedule"
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInceptiaUser={isInceptiaUser}
            fetchItems={() => notificationService.SearchSchedules({})}
            createItem={(schedule: NotificationScheduleDto) =>
                notificationService.CreateSchedule(schedule).then(() => {})
            }
            updateItem={(id: number, schedule: NotificationScheduleDto) =>
                notificationService.UpdateSchedule(id, schedule).then(() => {})
            }
            deleteItem={notificationService.DeleteSchedule}
            renderFormFields={renderFormFields}
            renderTableColumns={renderTableColumns}
            renderTableHeaders={renderTableHeaders}
        />
    );
};

export default NotificationScheduleCrud;
