import { Container } from '@mui/material';
import DashboardCenterNotifications from "../components/notifications/DashboardCenterNotificatins";

export default function Notifications() {
  return (
    <>
      <Container sx={{ py: 4 }}>
        <DashboardCenterNotifications />
      </Container>
    </>
  );
}
