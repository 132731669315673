import { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { pageHeaderSX } from '../../utilities/CSS';
import CustomTabs, { CustomTabPanel } from '../CustomTabs';
import NotificationTemplateCrud from "./NotificationTemplateCrud";
import NotificationScheduleCrud from "./NotificationScheduleCrud";
import { OrganizationContext, OrganizationContextProvider } from '../../contexts/OrganizationContext';
import KeycloakService from '../../services/KeycloakService';

export default function DashboardCenterNotifications() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [clientID, setClientID] = useState<number | undefined>(undefined);
  const { organization } = useContext(OrganizationContext);
  const [isInceptiaUser, setIsInceptiaUser] = useState<boolean>(false);

  useEffect(() => {
    if(organization?.opeid === "INCEPTIA")
      setClientID(0);
    else 
      setClientID(organization?.id);
  }, [organization]);

  useEffect(() => {
    if (["Admin", "Manager", "Operations", "SrOperations"].some(g => KeycloakService.hasGroup([g]))) {
      setIsInceptiaUser(true);
    }
}, []);   

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
  };

  return (
    <>
      <OrganizationContextProvider>
        <Typography variant='h1' sx={{ ...pageHeaderSX, mb: 4.5 }}>Notifications</Typography>

        <CustomTabs
          ariaTabAndPanelPrefix='notifications'
          aria-label='Notifications Tabs'
          allowScrollButtonsMobile
          selectedTab={selectedTab}
          variant='scrollable'
          tabs={[
              { label: 'Manage Templates' },
              { label: 'Manage Schedules' },
          ]}
          handleTabChange={handleTabChange}
        />
        <CustomTabPanel index={0} selectedTab={selectedTab}>
          <NotificationTemplateCrud clientId={clientID} isInceptiaUser={isInceptiaUser} />
        </CustomTabPanel>
        <CustomTabPanel index={1} selectedTab={selectedTab} autoHeight>
          <NotificationScheduleCrud isInceptiaUser={isInceptiaUser} />
        </CustomTabPanel>
      </OrganizationContextProvider>
    </>
  );
}
