import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLoading } from '../components/LoadingProvider';
import { enqueueSnackbar } from 'notistack';
import { ToDoListProps } from './ToDoList';
import userService from '../services/UserService';
import orgService from '../services/OrgService';
import KeycloakService from '../services/KeycloakService';
import { ProfileCompleteProps } from '../utilities/HelperFunctions';
import ConfirmationDialog from './ConfirmationDialog';
import { Grid, Typography } from '@mui/material';
import CustomTextField from './CustomTextField';
import { formatSSN } from '../utilities/HelperFunctions';
import { iUserLink } from '../utilities/APIInterfaces';
import * as yup from 'yup';
import { useFormik } from 'formik';



export interface OrgPurlProps {
    orgPurl: string;
    setOrgPurl: React.Dispatch<React.SetStateAction<string>>;
}

export interface SSNRequiredProps {
    ssnRequired: string;
    setSsnRequired: React.Dispatch<React.SetStateAction<string>>;
}

export interface FormUUIDProps {
    formUuid: string;
    setFormUuid: React.Dispatch<React.SetStateAction<string>>;
}

interface SearchParamHandlerProps {
    todoListProps: ToDoListProps;
    orgPurlProps: OrgPurlProps;
    ssnRequiredProps: SSNRequiredProps;
    formUuidProps: FormUUIDProps;
    profileCompleteProps: ProfileCompleteProps
    processParams: boolean;
}

const SearchParamHandler: React.FC<SearchParamHandlerProps> = (props: SearchParamHandlerProps) => {
    const [searchParams] = useSearchParams();
    const { setLoading } = useLoading();
    const [isStudentLinkDialogOpen, setIsStudentDialogOpen] = useState(false);
    const [isLinking, setIsLinking] = useState(false);
    const [studentLink, setStudentLink] = useState<iUserLink>();

    const validationSchema = yup.object().shape({
        ssn: yup.string().required("Please enter the student's SSN").min(9, "Please enter a valid SSN"),
        dob: yup.string().required("Please enter the student's date of birth")
    });

    const formik = useFormik({
        initialValues: {
            ssn: '',
            dob: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLinking(true);
        }
    });

    useEffect(() => {
        if (isLinking) {
            userService.LinkUserRelationship(KeycloakService.getId(),studentLink?.userLinkUUID, formik.values.ssn, formik.values.dob).then((result) => {
                if (result) {
                    enqueueSnackbar("Account Linked", { variant: "success" });
                    props.todoListProps.setIsToDoListDataLoaded(false);
                    setIsStudentDialogOpen(false);
                } else {
                    //todo limit the number of attempts?
                    enqueueSnackbar("Unable to link account", { variant: "error" });
                }
                setIsLinking(false);
            });
    }
    }, [isLinking]);
    const handlePurl = (orgPurl: string) => {
        //logged in 
        if (KeycloakService.isLoggedIn()) {
            if (props.profileCompleteProps.profileComplete && props.processParams) {
                userService.AssignToOrg(orgPurl, KeycloakService.getId(), setLoading);
                // clear localstorage
                localStorage.removeItem('formsPurl');
                localStorage.removeItem('formsFormUuid');
                localStorage.removeItem('formsUserLink');
            }
            props.orgPurlProps.setOrgPurl(orgPurl);

            if (orgPurl) {
                orgService.GetSSNRequired(orgPurl, setLoading).then((ssnRequired) => {
                    props.ssnRequiredProps.setSsnRequired(ssnRequired);
                });
            }
        }
        //not logged in, this might not be needed anymore since we're sending them to keycloak instead.
        else {
            orgService.CheckForValidPURL(orgPurl, setLoading).then((isValid) => {
                if (!isValid) {
                    enqueueSnackbar("Unable to process account. Please use the requested link provided by your school", { variant: "error" })
                } else {
                    let orgPurl = searchParams.get("purl") || null;
                    if (orgPurl != null) {
                        localStorage.setItem('formsPurl', orgPurl);
                    }
                    
                    let formUuid = searchParams.get("formUuid") || null;
                    if (formUuid != null) {
                        localStorage.setItem('formsFormUuid', formUuid);
                    }
            
                    let studnetLinkUUID = searchParams.get("userLink") || null;
                    if (studnetLinkUUID != null) {
                        localStorage.setItem('formsUserLink', studnetLinkUUID);
                    }
                }
            })
        }
    }
    const handleFormUuid = (formUuid: string, orgPurl: string) => {
        if (KeycloakService.isLoggedIn()) {
            if (props.profileCompleteProps.profileComplete && props.processParams) {
                userService.AssignFormPurlToUser(formUuid, orgPurl, KeycloakService.getId(), setLoading).then((isValid) => {
                    props.todoListProps.setIsToDoListDataLoaded(false);
                });
                // clear localstorage
                localStorage.removeItem('formsPurl');
                localStorage.removeItem('formsFormUuid');
                localStorage.removeItem('formsUserLink');
            }
            props.formUuidProps.setFormUuid(formUuid);
        }

    }
    const handleStudentLink = async (studnetLinkUUID: string) => {
        if (KeycloakService.isLoggedIn()) {
            if (props.profileCompleteProps.profileComplete && props.processParams) {
                await userService.GetUserLink(studnetLinkUUID, setLoading).then((result: iUserLink) => {
                    if (result && result.status === 'Request') {
                        setStudentLink(result);
                        setIsStudentDialogOpen(true);
                    }else{
                        enqueueSnackbar("That link has already been created", { variant: "error" });
                    }
                    // clear localstorage
                    localStorage.removeItem('formsPurl');
                    localStorage.removeItem('formsFormUuid');
                    localStorage.removeItem('formsUserLink');
                });
            }

        }
    }
    useEffect(() => {
        let orgPurl = searchParams.get("purl") || null;
        let formUuid = searchParams.get("formUuid") || null;
        let studnetLinkUUID = searchParams.get("userLink") || null;

        if (orgPurl == null) {
            orgPurl = localStorage.getItem('formsPurl') || null;
        } else {
            localStorage.setItem('formsPurl', orgPurl);
        }

        if (formUuid == null) {
            formUuid = localStorage.getItem('formsFormUuid') || null;
        } else {
            localStorage.setItem('formsFormUuid', formUuid);
        }

        if (studnetLinkUUID == null) {
            studnetLinkUUID = localStorage.getItem('formsUserLink') || null;
        } else {
            localStorage.setItem('formsUserLink', studnetLinkUUID);
        }

        if (orgPurl) {
            handlePurl(orgPurl);
        }
        if (formUuid && orgPurl) {
            handleFormUuid(formUuid, orgPurl);
        }
        if (studnetLinkUUID) {
            handleStudentLink(studnetLinkUUID);
        }
    }, [searchParams, props.profileCompleteProps.profileComplete, props.processParams]);

    return (
        <ConfirmationDialog
            ariaPrefix='contributor'
            dialogTitle="Confirm Student's Information"
            singleButton={false}
            ctaButtonText='Confirm'
            ctaButtonWorkingText='Confirming...'
            cancelButtonText='Cancel'
            open={isStudentLinkDialogOpen}
            setOpenDialog={setIsStudentDialogOpen}
            isWorking={isLinking}
            setIsWorking={()=>{formik.handleSubmit()}}
        >
            <Typography variant='body2' sx={{ mb: 2.5 }}><b>{studentLink?.userName} </b> has invited you as a contributor. Fill out the information below to confirm student relationships and link your accounts. </Typography>
                    <CustomTextField
                        id='dob'
                        labelText='Date of Birth'
                        shrinkLabel
                        type='date'
                        value={formik.values.dob}
                        onChange={(e) => formik.setFieldValue('dob',e.target.value)}
                        error={formik.touched.dob && Boolean(formik.errors.dob)}
                        helperText={formik.touched.dob && formik.errors.dob}
                        margin="normal"
                    />

                    <CustomTextField
                        id='ssn'
                        margin="normal"
                        labelText='Social Security Number'
                        shrinkLabel
                        value={formatSSN(formik.values.ssn)}
                        onChange={(e) => formik.setFieldValue('ssn',e.target.value)}
                        error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                        helperText={formik.touched.ssn && formik.errors.ssn}
                    />
        </ConfirmationDialog>
    )
};

export default SearchParamHandler;


