import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme } from './themes/DashboardTheme';
import { ToDoListProps } from './components/ToDoList';
import {  
  Route, 
  Routes, 
  useNavigate, 
  useLocation } from 'react-router-dom';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { UserContext } from './contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { Button, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { LoadingProvider } from './components/LoadingProvider';
import { SideBarDrawerProps } from './components/SideBarDrawer';
import DashboardHome from './pages/DashboardHome';
import DashboardCommunications from './pages/DashboardCommunications';
import DashboardDocuments from './pages/DashboardDocuments';
import DashboardSettings from './pages/DashboardSettings';
import SignUp from './pages/SignUp';
import AdminHome from './pages/AdminHome';
import AdminForm from './pages/AdminForm';
import AdminUsers from './pages/AdminUsers';
import KeycloakService from './services/KeycloakService';
import SearchParamHandler, { FormUUIDProps, OrgPurlProps, SSNRequiredProps } from './components/SearchParamHandler';
import userService from './services/UserService';
import AdminCenterHome from './components/AdminCenterHome';
import { RoleProtectedRoute } from './components/RoleProtectedRoute';
import { AdminRoleNodes } from './utilities/APIInterfaces';
import { ProfileComplete, ProfileCompleteProps } from './utilities/HelperFunctions';
import AdminApplicantForm from './components/AdminApplicantForm';
import AdminFormCrud from './pages/AdminFormCrud';
import Notifications from './pages/Notifications';
import AdminUserCrud from './pages/AdminUserCrud';

function App() {
  const userContext = useContext(UserContext);
  const [isToDoListDataLoaded, setIsToDoListDataLoaded] = useState(false);
  const toDoListProps: ToDoListProps = { isToDoListDataLoaded, setIsToDoListDataLoaded };
  const [ssnRequired, setSsnRequired] = useState('REQUIRED');
  const ssnRequiredProps: SSNRequiredProps = { ssnRequired:ssnRequired, setSsnRequired };
  const [orgPurl, setOrgPurl] = useState('');
  const orgPurlProps: OrgPurlProps = { orgPurl:orgPurl, setOrgPurl };
  const [formUuid, setFormUuid] = useState('');
  const formUuidProps: FormUUIDProps = { formUuid:formUuid, setFormUuid };
  const [profileComplete,setProfileComplete] = useState(false);
  const profileCompleteProps : ProfileCompleteProps = {profileComplete, setProfileComplete};
  const [processParams, setProcessParams] = useState(false);

  // const [deviceRemembered, setDeviceRemembered] = useState(true);//assume device is remembered to prevent pop up from showing up then quickly disappearing
  //TODO:: device remembrance is broken 
  // const rememberDeviceButtonProps: RememberDeviceButtonProps = { deviceRemembered, setDeviceRemembered, user: userId }


  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const widthUnderMd = useMediaQuery(theme.breakpoints.down('md'));
  const [openSideBarDrawer, setOpenSideBarDrawer] = useState(() => {
    // if width is under md, then drawer should be closed
    if (widthUnderMd) return false;
    return true;
  });
  useEffect(() => {
    setOpenSideBarDrawer(!widthUnderMd);
  }, [widthUnderMd]);

  const sbdProps: SideBarDrawerProps = {
    widthUnderMd: widthUnderMd,
    openSideBarDrawer: openSideBarDrawer,
    setOpenSideBarDrawer: setOpenSideBarDrawer
  };
  useEffect(() => {
    (async () => {
      if (userContext.user === null) {
        await userService.GetUser(KeycloakService.getId(), (v) => v,false)
        .then((result) => {
          let user = userService.MapToUser(result);
          userContext.setUser(user);
          if (!ProfileComplete(user, profileCompleteProps)){
            navigate('/signup')
          }
          Object.keys(new AdminRoleNodes()).forEach((role) => {
            if (KeycloakService.hasGroup([role]) && !location.pathname.includes("/admin")) {
              navigate('/admin/forms');
            }else{
              setProcessParams(true);
            }
          });
        }).catch(() => {
          console.error('user not found');
          navigate('/signup');
        })
      }
    })();
  }, [userContext.user]);
  
  if (KeycloakService.isLoggedIn()) {
    return (
            <SnackbarProvider
              variant='error'
              autoHideDuration={10000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              action={(ref) => (<Button onClick={() => closeSnackbar(ref)} size="small" sx={{ color: 'white' }}>Close</Button>)}
            >
              <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <LoadingProvider>
                  <Routes>
                    {["/","/*","/login", "/dashboard"].map((path, index) => <Route key={index} path={path} element={<DashboardHome sideBarDrawerProps={sbdProps} todoListProps={toDoListProps} />} />)}
                    <Route path="/dashboard/communications" element={<DashboardCommunications {...sbdProps} />} />
                    <Route path="/dashboard/documents" element={<DashboardDocuments {...sbdProps} />} />
                    <Route path="/dashboard/settings" element={<DashboardSettings sideBarDrawerProps={sbdProps} />} />
                    <Route path="/signup" element={<SignUp orgPurlProps={orgPurlProps} ssnRequiredProps={ssnRequiredProps} />} />                    
                    <Route path="/admin" element={<RoleProtectedRoute groups={Object.keys(new AdminRoleNodes())}><AdminHome {...sbdProps} /></RoleProtectedRoute>}>
                      <Route path="/admin/forms" element={<AdminCenterHome />} />
                      <Route path="/admin/form/:formUuid" element={<AdminForm />} />
                      <Route path="/admin/formedit/:formUuid" element={<AdminFormCrud action='edit' />} />
                      <Route path="/admin/formadd" element={<AdminFormCrud action='add' />} />
                      <Route path="/admin/users" element={<AdminUsers />} />
                      <Route path="/admin/useredit/:authId" element={<AdminUserCrud action='edit' />} />
                      <Route path="/admin/form/:formUuid/applicant/:userId/:submissionUuid" element={<AdminApplicantForm />} />
                      <Route path="/admin/notifications" element={<Notifications />} />
                    </Route>
                  </Routes>
                </LoadingProvider>
              <SearchParamHandler todoListProps={toDoListProps} orgPurlProps={orgPurlProps} ssnRequiredProps={ssnRequiredProps} formUuidProps={formUuidProps} profileCompleteProps={profileCompleteProps} processParams={processParams} />
              </ThemeProvider>
            </SnackbarProvider>
    );
  } else {
    return <b>Not Authorized</b>
  }
}

export default App;
