import React, { useCallback } from 'react';
import { Grid, TextField, Typography, TableCell, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import * as yup from 'yup';
import notificationService from '../../services/NotificationService';
import { ApplicationTypeEnum, NotificationTemplateStatusEnum, NotificationCommunicationTypeEnum, NotificationTemplateDto, OperationTypeEnum } from '../../apis/notifications';
import ManageCrudPage, { DropdownStyles } from './ManageCrudPage';

interface NotificationTemplateCrudProps {
    clientId: number | undefined;
    isInceptiaUser: boolean;
}

const NotificationTemplateCrud = ({ clientId = 0, isInceptiaUser = false }: NotificationTemplateCrudProps) => {
    const initialValues: NotificationTemplateDto = {
        name: '',
        communication_type: NotificationCommunicationTypeEnum.Email,
        application_type: ApplicationTypeEnum.FormsApp,
        client_id: clientId,
        status: NotificationTemplateStatusEnum.Approved,
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        communication_type: yup.string().oneOf(Object.values(NotificationCommunicationTypeEnum)).required(),
        subject: yup.string().notRequired(),
        body: yup.string().required(),
    });

    const renderFormFields = (formik: any) => (
        <>
            <Grid container rowGap={4} columnSpacing={3.5} sx={{ mb: 6.25 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="name"
                        label="Name"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        inputProps={{
                            readOnly: !isInceptiaUser
                        }}
                        InputProps = {{
                            className: isInceptiaUser ? undefined : "Mui-disabled"
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth error={formik.touched.communication_type && Boolean(formik.errors.communication_type)} sx={DropdownStyles}>
                        <InputLabel id="communication-type-label">Communication Type</InputLabel>
                        <Select
                            labelId="communication-type-label"
                            id="communication_type"
                            name="communication_type"
                            label="Communication Type"
                            value={formik.values.communication_type}
                            onChange={formik.handleChange}
                            disabled={isInceptiaUser ? undefined : true}
                        >
                            {Object.values(NotificationCommunicationTypeEnum).map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.communication_type && formik.errors.communication_type ? (
                            <Typography variant="caption" color="error">{String(formik.errors.communication_type)}</Typography>
                        ) : null}
                    </FormControl>
                </Grid>

                {formik.values.communication_type === NotificationCommunicationTypeEnum.Email && (
                    <Grid item xs={12}>
                        <TextField
                            id="subject"
                            label="Subject"
                            fullWidth
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                            helperText={formik.touched.subject && formik.errors.subject}
                            inputProps={{
                                readOnly: !isInceptiaUser
                            }}
                            InputProps = {{
                                className: isInceptiaUser ? undefined : "Mui-disabled"
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TextField
                        id="body"
                        label="Body"
                        fullWidth
                        multiline
                        rows={4}
                        value={formik.values.body}
                        onChange={formik.handleChange}
                        error={formik.touched.body && Boolean(formik.errors.body)}
                        helperText={formik.touched.body && formik.errors.body}
                        inputProps={{
                            readOnly: !isInceptiaUser
                        }}
                        InputProps = {{
                            className: isInceptiaUser ? undefined : "Mui-disabled"
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );

    const renderTableHeaders = () => (
        <>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Communication Type</TableCell>
        </>
    );

    const renderTableColumns = (item: NotificationTemplateDto) => (
        <>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.status}</TableCell>
            <TableCell>{item.communication_type}</TableCell>
        </>
    );

    const fetchItems = useCallback(async () => {
        let templates = await notificationService.SearchTemplates({ 
            filters: [{ 
                field: "client_id", 
                operation: OperationTypeEnum.EQUALS, 
                value: clientId?.toString()
            }]});
        if (clientId) {
            let defaultTemplates = await notificationService.SearchTemplates({ 
                filters: [{ 
                    field: "client_id", 
                    operation: OperationTypeEnum.EQUALS, 
                    value: "0"
                }]});
            templates = [...templates, ...defaultTemplates];
        }
        return templates;
    }, [clientId]);

    return (
        <ManageCrudPage<NotificationTemplateDto>
            title="Manage Notification Templates"
            entityName="Template"
            initialValues={initialValues}
            validationSchema={validationSchema}
            isInceptiaUser={isInceptiaUser}
            fetchItems={fetchItems}
            createItem={(template: NotificationTemplateDto) =>
                notificationService.CreateTemplate({...template, client_id: clientId }).then(() => {})
            }
            updateItem={(id: number, template: NotificationTemplateDto) =>
                notificationService.UpdateTemplate(id, template).then(() => {})
            }
            deleteItem={notificationService.DeleteTemplate}
            renderFormFields={renderFormFields}
            renderTableColumns={renderTableColumns}
            renderTableHeaders={renderTableHeaders}
        />
    );
};

export default NotificationTemplateCrud;