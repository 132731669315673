/* tslint:disable */
/* eslint-disable */
/**
 * notification-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface NotificationScheduleDto
 */
export interface NotificationScheduleDto {

    /**
     * @type {number}
     * @memberof NotificationScheduleDto
     */
    id?: number;

    /**
     * The start date from which the schedule becomes active.
     *
     * @type {Date}
     * @memberof NotificationScheduleDto
     */
    start_date: Date;

    /**
     * The end date until which the schedule remains active.
     *
     * @type {Date}
     * @memberof NotificationScheduleDto
     */
    end_date: Date;

    /**
     * The time of day the notification is to be sent.
     *
     * @type {string}
     * @memberof NotificationScheduleDto
     */
    send_time: string;

    /**
     * The timezone name in the form \"Area/Location\", e.g., \"America/New_York\"
     *
     * @type {string}
     * @memberof NotificationScheduleDto
     */
    timezone: string;

    /**
     * The timestamp when the record was created.
     *
     * @type {Date}
     * @memberof NotificationScheduleDto
     */
    created_at?: Date;

    /**
     * The timestamp when the record was last updated.
     *
     * @type {Date}
     * @memberof NotificationScheduleDto
     */
    updated_at?: Date;

    /**
     * The type of interval for the schedule (days, weeks, months).
     *
     * @type {string}
     * @memberof NotificationScheduleDto
     */
    interval_type?: NotificationScheduleDtoIntervalTypeEnum;

    /**
     * The interval for the schedule.
     *
     * @type {number}
     * @memberof NotificationScheduleDto
     */
    interval_value?: number;

    /**
     * An array of days of the week (0 for Sunday, 1 for Monday, etc.).
     *
     * @type {Array<number>}
     * @memberof NotificationScheduleDto
     */
    specific_days_of_week?: Array<NotificationScheduleDtoSpecificDaysOfWeekEnum>;

    /**
     * An array of days of the month (1-31).
     *
     * @type {Array<number>}
     * @memberof NotificationScheduleDto
     */
    specific_days_of_month?: Array<number>;

    /**
     * The maximum number of attempts to send the notification.
     *
     * @type {number}
     * @memberof NotificationScheduleDto
     */
    max_notification_attempts?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationScheduleDtoIntervalTypeEnum {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months'
}
/**
 * @export
 * @enum {string}
 */
export enum NotificationScheduleDtoSpecificDaysOfWeekEnum {
    _0 = '0',
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _4 = '4',
    _5 = '5',
    _6 = '6'
}

