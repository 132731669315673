import { Survey } from "survey-react-ui";
import { Model } from 'survey-core';
import { InceptiaLight } from '../themes/surveyjs-inceptia-theme';
import { Autocomplete, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { iAdminFormSubmissionsData } from "../contexts/AdminFormSubmissionsDataContext";
import adminFormsService from "../services/AdminFormsService";
import { inceptiaGreenAlphaColors } from "../utilities/CSS";
import { iDocumentDTO, iFormTag, iSubmission, iUserDTO } from "../utilities/APIInterfaces";
import { Status, StatusLabel, ValidManualStatuses, InvalidManualStatuses, VerifierType } from "../utilities/FormEnums";
import tagService from "../services/TagService";
import { handleClearFiles, handleUploadFiles } from '../utilities/SurveyJS';
import { useSnackbar } from 'notistack';
import formsService from "../services/FormsService";
import { UserContext } from "../contexts/UserContext";
import ConfirmationDialog from "./ConfirmationDialog";
import KeycloakService from '../services/KeycloakService';

interface FormSubmissionProps {
  form: any;
  submission: iAdminFormSubmissionsData;
  setSubmission: (v: iAdminFormSubmissionsData) => void;
  setIsServiceHistoryLoaded: (v: boolean) => void;
  verifier_Type_Id: number;
}

const editableKey = "ALWAYSEDIT";

export default function FormSubmission(props: FormSubmissionProps) {
    const userContext = useContext(UserContext);
    const [model, setModel] = useState<Model | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);
    const [tagOptions, setTagOptions] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [hasTags, setHasTags] = useState<boolean>(true);
    const [selectedTags, setSelectedTags] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [updatingTags, setUpdatingTags] = useState(false);
    const [assigneeOptions, setassigneeOptions] = useState<{ label: string | undefined; value: string; }[]>([]);
    const [selectedAssignee, setSelectedAssignee] = useState<{ label: string | undefined; value: string; }>();
    const [updatingAssignee, setUpdatingAssignee] = useState(false);
    const [statusOptions, setStatusOptions] = useState<{ label: string | undefined; value: number; }[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<{ label: string | undefined; value: number; }>();
    const [previousSelectedStatus, setPreviousSelectedStatus] = useState<{ label: string | undefined; value: number; }>();
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const statusesForConfirmModal = [Status.APPROVED, Status.DECLINED];
    const [submissionChanged, setSubmissionChanged] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [disableChangeOptions, setDisableChangeOptions] = useState(false);
    const keycloakInceptiaGroups = ['Institution', 'InstitutionAdmin'];
    const keycloakInstitutionGroups = ['Manager', 'SrOperations', 'Operations'];
  
    useEffect(() => {
      // If the form verifier type = 'Inceptia' and the user's keycloak group is in the provided groups...
      if ((props.verifier_Type_Id === VerifierType.INCEPTIA && keycloakInceptiaGroups.some(group => KeycloakService.hasGroup([group]))) 
        || (props.verifier_Type_Id === VerifierType.INSTITUTION && keycloakInstitutionGroups.some(group => KeycloakService.hasGroup([group])))) {
        // ...then disable the change menu options (Change Status, Assignee, and Tags) under the 'Actions' button
        // And the Autocompletes for changing Assignee, Tags, and Status on the "Applicant Details" page
        setDisableChangeOptions(true);
      }
    }, [props.verifier_Type_Id]);

    // Users in the Keycloak "Admin" or "Manager" groups can save submission changes - used to show/hide save button
    const canSaveSubmissionChanges = KeycloakService.hasGroup(['Admin']) || KeycloakService.hasGroup(['Manager']);

    // Some "special sauce" happening here to adjust the positioning of the save button when the user scrolls to the bottom of the page
    const [saveSubmissionAnswersButtonBottomStyle, setSaveSubmissionAnswersButtonBottomStyle] = useState('15px');
    useEffect(() => {
      const handleScroll = () => {
        // If the user has scrolled to the bottom, move the "sticky" button up to make it "appear" to be in the lower right corner of the form's container
        const newBottomStyle = (window.innerHeight + window.scrollY >= document.body.offsetHeight) ? '50px' : '15px';
        if (newBottomStyle !== saveSubmissionAnswersButtonBottomStyle) setSaveSubmissionAnswersButtonBottomStyle(newBottomStyle);
      };

      // Only care about repositioning the button IF the button is actually visible
      if (canSaveSubmissionChanges) window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
    }, [saveSubmissionAnswersButtonBottomStyle, canSaveSubmissionChanges]);

    const { enqueueSnackbar } = useSnackbar();
    const [submissionDocuments, setSubmissionDocuments] = useState<iDocumentDTO[]>(props.submission.documents);

    const SaveSurveyData = async (surveyModel: Model, showSnackbar: boolean = false) => {
      const data = surveyModel.data;
      data.pageNo = surveyModel.currentPageNo;
  
      const updatedSubmission: iSubmission = {
        id: props.submission.id,
        uuid: props.submission.uuid,
        formsVersionId: props.submission.formsVersionId,
        submittedBy: props.submission.submittedBy,
        updatedBy: userContext.user?.id || -1,
        submissionJson: JSON.stringify(data),
        submissionTypeId: props.submission.submissionTypeId,
        orgId: props.form.org_Id,
        statusId: props.submission.statusId,
        documents: submissionDocuments,
        isRetakingForm: false,
        isSubmittingForm: false
      };

      setIsSaving(true);

      await formsService.SaveFormSubmission(updatedSubmission, (v) => v)
        .then((result) => {
          props.setSubmission({
            ...props.submission,
            submissionJson: updatedSubmission.submissionJson
          });
          if (showSnackbar) enqueueSnackbar('Submission saved.', { variant: 'success', autoHideDuration: 3000 });
        })
        .catch((error) => {
          enqueueSnackbar(error.toString());
        }).finally(() => {
          setIsSaving(false);
        });
    };
    
    useEffect(() => {
        const SetupTags = async () => {
            if (props.submission.formUuid !== null) {
                await tagService.GetFormTags(props.submission.formUuid, (v) => { }).then((result) => {
                    let options: { label: string | undefined; value: number; }[] = [];
                    if(result !== null && result.length > 0){
                        result.forEach((tag: iFormTag) => {
                            options.push({ label: tag.tag_Name, value: tag.id })
                        });
                        mapExistingTags(options);
                        setTagOptions(options);
                    }else{
                        setHasTags(false);
                    }
                }
                );
            }
        }

        const mapExistingTags = (formTags: any) => {
            let selectedTags: { label: string | undefined; value: number; }[] = [];
            if (props.submission.tags !== null) {
                props.submission.tags.forEach((tag: any) => {
                    if (formTags !== undefined) {
                        let tagOption = formTags.find((formTag: any) => formTag.label === tag.tag_Name);
                        if (tagOption !== undefined) {
                            selectedTags.push(tagOption);
                        }
                    }
                });
            }
            setSelectedTags(selectedTags);
        }

        const SetupAssignees = async () => {
            let options: { label: string | undefined; value: string; }[] = [];
            const formUuid = props.submission.formUuid || ""; 
            await adminFormsService.GetFormAssignees(formUuid, (v)=>{}).then((result) => {
                result.forEach((user: iUserDTO) => {
                    options.push({ label: `${user.first_Name} ${user.last_Name}`, value: user.auth_Id })
                });
                if (props.submission.assignee !== null) {
                    let assignee = options.find((option) => option.value === props.submission.assignee?.auth_Id);
                    if (assignee !== undefined) {
                        setSelectedAssignee(assignee);
                    }
                }
            })
            setassigneeOptions(options);
        };
        const SetupStatus = () =>{
            let currentStatus = props.submission.statusId as Status;
            let statusSet = false;
            let options: { label: string | undefined; value: number; }[] = [];
            ValidManualStatuses.forEach((status) => {
                let statusChoice;
                statusChoice = { label: StatusLabel.get(status), value: status }
                if(status === currentStatus){
                    setSelectedStatus(statusChoice);
                    statusSet = true;
                }
                options.push(statusChoice);
            });
            if(!statusSet){
                let statusChoice = { label: StatusLabel.get(currentStatus), value: currentStatus };
                //add the current status to the list of options, but it's not a valid status, the component will account for that
                options.push(statusChoice);
                setSelectedStatus(statusChoice);
            }
            setStatusOptions(options);  

        }

        if (props.submission !== undefined && !loaded) {
            SetupTags()
            SetupAssignees()
            SetupStatus()
            const surveyModel = new Model(props.submission.formJson);
            if (props.submission.submissionJson !== null) {
                surveyModel.data = JSON.parse(props.submission.submissionJson);
            }
            surveyModel.applyTheme(InceptiaLight);
            setModel(surveyModel);

            // Removing read only mode on whole survey (FEBS-989) to allow for some editable fields
            // surveyModel.mode = 'display';
            surveyModel.getAllQuestions().map((q) => {
                q.readOnly = !q.name.includes(editableKey);
            });

            surveyModel.onClearFiles.add(async (_, options) => {
                setSubmissionDocuments(await handleClearFiles(userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar));
                SaveSurveyData(surveyModel);
            });
            
            surveyModel.onUploadFiles.add(async (_, options) =>{
                setSubmissionDocuments(await handleUploadFiles(props.submission.authId, userContext.user?.authId!, options, submissionDocuments, enqueueSnackbar));
                SaveSurveyData(surveyModel);
            });

            // If the user can save changes to the submission, enable the save button when a change is made to the form
            if (canSaveSubmissionChanges) {
              surveyModel.onValueChanged.add((sender, options) => {
                setSubmissionChanged(true);
              });
            }
        }
    }, [props.submission, loaded]);

    useEffect(() => {
        if (assigneeOptions.length > 0 && statusOptions.length > 0 && !loaded) {
            if (hasTags && tagOptions.length > 0) {
                setLoaded(true);
            } else if (!hasTags) {
                setLoaded(true);
            }
        }
    }, [assigneeOptions, tagOptions, hasTags, statusOptions, loaded]);

    useEffect(() => {
        if(updatingTags){
            tagService.SetSubmissionsTags(props.submission.formUuid!, [props.submission.uuid], selectedTags.map((tag) => tag.value), true, (v) => {}).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingTags(false);
                props.setIsServiceHistoryLoaded(false);
            })
        }
    }, [selectedTags]);

    useEffect(() => {
        if (updatingAssignee) {
            adminFormsService.UpdateSubmisionAssignee([props.submission.uuid], selectedAssignee?.value ?? '', (v) => { }).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingAssignee(false);
                props.setIsServiceHistoryLoaded(false);
            });
        }
    }, [updatingAssignee]);

    useEffect(() => {
        if (updatingStatus) {
            adminFormsService.UpdateSubmissionStatuses([props.submission.uuid], selectedStatus?.value as Status , (v) => { }).then((result) => {
                //alert here?
            }).finally(() => {
                setUpdatingStatus(false);
                props.setIsServiceHistoryLoaded(false);
            });
        }
    }, [updatingStatus]);

    return (
        <>
        {loaded && (
        <Grid container rowSpacing={2}>
            <Grid 
                item xs={4} 
                key={0}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mt: 3,
                    pl: 3
                }}
            >
                <Autocomplete
                    id={`change-assignee-autocomplete`}
                    options={assigneeOptions}
                    value={selectedAssignee}
                    disabled={updatingAssignee || disableChangeOptions}
                    loading={assigneeOptions.length === 0}
                    onChange={(e, value: any) => {
                        setSelectedAssignee(value);
                        setUpdatingAssignee(true);
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={'Assignee'}
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid>
            <Grid 
                item xs={4} 
                key={1}                 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                }}
            >
                <Autocomplete
                    id={`change-tags-autocomplete`}
                    getOptionLabel={(option) => option.label || ''}
                    multiple={true}
                    options={tagOptions}
                    value={selectedTags}
                    disableClearable={true}
                    disabled={updatingTags || disableChangeOptions}
                    loading={tagOptions.length === 0}
                    onChange={(e, value: any) => {
                        setSelectedTags(value);
                        setUpdatingTags(true);
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Tags'
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid> 
            <Grid 
                item xs={4} 
                key={2}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 3,
                    pr: 3
                }}
            >
                <Autocomplete
                    id={`change-status-autocomplete`}
                    options={statusOptions}
                    value={selectedStatus}
                    disabled={updatingStatus || disableChangeOptions}
                    disableClearable={true}
                    loading={statusOptions.length === 0}
                    onChange={(e, value: any) => {
                        setPreviousSelectedStatus(selectedStatus);
                        setSelectedStatus(value);
                        if(statusesForConfirmModal.includes(value.value as Status)){
                            setConfirmModalOpen(true);
                        }else{
                            setUpdatingStatus(true);
                        }
                    }}
                    getOptionDisabled={(option) => InvalidManualStatuses.has(option.value as Status)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={'Status'}
                            InputLabelProps={{ shrink: true }}
                            />}
                    //Style the selected items in the dropdown list
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.hover
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
                                    backgroundColor: inceptiaGreenAlphaColors.selected
                                },
                                '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                                    backgroundColor: inceptiaGreenAlphaColors.selectedHover
                                },
                                
                            }
                        }
                    }}
                    sx={{ width: '98%' }}
                />
            </Grid>
            <Grid 
                item xs={12}
                sx={{ mt: 1 }}
            >
                {model &&  <Survey model={model}/>}
                {model && canSaveSubmissionChanges && (<Button
                  variant='contained'
                  disabled={isSaving || ! submissionChanged}
                  sx={{
                    position: 'sticky',
                    left: '100%',
                    bottom: saveSubmissionAnswersButtonBottomStyle,
                    transform: 'translateX(-10px)',
                    transition: 'bottom 0.5s ease',

                    bgcolor: 'InceptiaGreen.main',
                    fontSize: '1rem',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'InceptiaGreen.dark',
                    },
                    '&.Mui-disabled': { 
                      color: '#9e9e9e',
                      backgroundColor: '#d5d5d5'
                    }
                  }}
                  onClick={() => {
                    SaveSurveyData(model, true);
                    setSubmissionChanged(false);
                  }}
                >
                  {isSaving ? (<>
                    <CircularProgress
                      color='inherit'
                      size={20}
                      sx={{ mr: 1 }}
                    />
                    Saving Submission...
                  </>) : 'Save Submission Answers'}
                </Button>
                )}
            </Grid>
        </Grid>
        )}
        {confirmModalOpen && (
            <ConfirmationDialog
                ariaPrefix="change-status"
                dialogTitle="Change Status Confirmation"
                singleButton={false}
                ctaButtonText="Confirm"
                ctaButtonWorkingText="Confirming..."
                cancelButtonText="Cancel"
                open={confirmModalOpen}
                setOpenDialog={setConfirmModalOpen}
                setIsWorking={(accepted:boolean)=>{
                    if(accepted){
                        setUpdatingStatus(true);
                    }else{
                        setSelectedStatus(previousSelectedStatus);
                    }
                    setConfirmModalOpen(false)
                }}
            >
                <p>You are about to set the status to <b>{selectedStatus?.label}</b>. Are you sure? </p>
            </ConfirmationDialog>
        )}
    </>
    )
}


